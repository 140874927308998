#container {
    min-height:100vh!important;
    position:relative;
 }

#body {
    padding-bottom:70px;   /* Height of the footer + min margin from footer */
 }

body
{
    background-image: url('../bg_photo.jpg');
    background-size: 100%;
    margin:0;
    padding:0;
    height:100vh!important;
}

.primary-blue
{
    color: #203864!important;
}

.lighter-blue
{
    color: blue;
}

.x-picture-size
{
    height: 40px;
    width: 40px;
}

.decorative
{
    font-family: "Comic Sans MS", cursive, sans-serif;
    font-weight: bolder;
}

.btn-light
{
    border-radius: 40px!important;
    background-color: rgba(255,255,255,0.5)!important;
    border-color: rgba(255,255,255,0.7)!important;
    font-family: "Comic Sans MS", cursive, sans-serif!important;
    font-weight: bolder!important;
}

.header
{
    z-index: 99;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 0px 2px rgba(0,0,0,0.3);
    height: 60px;
}

.footer
{
    position:absolute;
    bottom:0;
    width:100%;
    height:60px;
    z-index: 99;
    background-color: rgba(255,255,255,0.3);
}

.login-icon
{
    font-size: 40px;
    color: #203864;
}

.homepage-text
{
    font-size: 25px;
    color: #285D9C;
}

.contact-us-text
{
    font-size: 15px;
    color: #285D9C;
}

.rounded-corners-lg
{
    border-radius: 75px!important;
}

.rounded-corners-md
{
    border-radius:25px!important;
}

.rounded-corners-md2
{
    border-radius:37px!important;
}

.rounded-corners-sm
{
    border-radius:20px!important;
}

.link-card-med
{
    width:12em;
    height:10em;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
}

.card-img-top-med
{
    border-top-left-radius: 40px!important;
    border-top-right-radius: 40px!important;
    border: solid!important;
    border-color: rgba(255,255,255,0.3);
    border-width: 4px!important;
    height:7em;
    width: 12em;
}


.link-card-med-large
{
    width:14em;
    height:12em;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
}

.card-img-top-med-large
{
    border-top-left-radius: 40px!important;
    border-top-right-radius: 40px!important;
    border: solid!important;
    border-color: rgba(255,255,255,0.3);
    border-width: 4px!important;
    height:9em;
    width: 14em;
}

.rounded-corners-small
{
    border-radius:20px!important;
}

.link-card-small
{
    width:10em;
    height:10em;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
}

.card-img-top-small
{
    border-top-left-radius: 20px!important;
    border-top-right-radius: 20px!important;
    border: solid!important;
    border-color: rgba(255,255,255,0.3);
    border-width: 2px!important;
    height:7em;
    width: 10em;
}

.rounded-corners-very-small
{
    border-radius:15px!important;
}

.link-card-very-small
{
    width:8em;
    height:7em;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
}

.card-img-top-very-small
{
    border-top-left-radius: 20px!important;
    border-top-right-radius: 20px!important;
    border: solid!important;
    border-color: rgba(255,255,255,0.3);
    border-width: 2px!important;
    height:5em;
    width: 8em;
}

.link-card
{
    width:18em;
    height:18em;
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
}

.link-tab
{
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
    border-color: rgba(255,255,255,0.3);
    border-width: 4px!important;
}

.link-tab-selected
{
    background-color: rgba(255,255,255,0.3);
    box-shadow: 2px 4px rgba(0,0,0,0.3);
    border-color: #203864!important;
    border-width: 4px!important;
}

.link-tab-size
{
    width: 50px;
    height: 20px;
}

.btn-primary
{
    background-color: #203864!important;
    border-color: #203864!important;
    border-width: 4px!important
}

.height-max
{
    height: 550px!important;
}

.overflow-scroll
{
    overflow-y:auto!important;
}

.input-page-base-card
{
    min-height:500px!important;
    max-height: 60vh!important;
 }

div::-webkit-scrollbar {
    width: 15px;
  }
  
div::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 20px;
    margin-bottom: 20px;
}
   
div::-webkit-scrollbar-thumb {
    background: #7490C3; 
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #203864; 
}

.bg-accent {
    background: #7490C3!important;
}

#footer-link:hover {
    text-decoration: none;
}

.login-form
{
    position: absolute;
    right: 10px;
    top: 60px;
    width: 250px;
    z-index: 99;
    background-color: rgba(198,212,237, 0.95);
    border-color: black;
    border-style: solid;
    border-width: 0px 2px 2px 2px;
    border-radius: 0px 0px 10px 15px;
}


.register-form
{
    position: absolute;
    right: 10px;
    top: 60px;
    z-index: 99;
    width: 400px;
    background-color: rgba(198,212,237, 0.95);
    border-color: black;
    border-style: solid;
    border-width: 0px 2px 2px 2px;
    border-radius: 0px 0px 10px 15px;
}


.forgot-password-form
{
    position: absolute;
    right: 10px;
    top: 60px;
    z-index: 99;
    width: 400px;
    min-height: 200px;
    background-color: rgba(198,212,237, 0.95);
    border-color: black;
    border-style: solid;
    border-width: 0px 2px 2px 2px;
    border-radius: 0px 0px 10px 15px;
}

.forgot-password-text
{
    color: #285D9C;
    font-size: 1.2rem;
}

.medium-text {
    font-size: 1.0rem;
}

.medium-large-text {
    font-size: 1.2rem;
}

.large-text {
    font-size: 2rem;
}

.small-text {
    font-size: 0.8rem;
}

.small-text-2 {
    font-size: 0.9rem;
}

.small-text-3 {
    font-size: 0.85rem;
}

.small-response-text {
    font-size: 0.9rem;
}

.very-small-text {
    font-size: 0.7rem;
}

.very-small-text2 {
    font-size: 0.55rem;
 }

.very-very-small-text {
    font-size: 0.4rem;
}

.backgroundTopBottom {
    background-color:  #B6D0EB;
   }

.trainLoadingLarger {
    height: 90%!important;
    width: 90%!important;
  }

.trainLoading {
    height: 40%!important;
    width: 40%!important;
  }
  
.trainLoadingSmaller {
    height: 20%!important;
    width: 20%!important;
  }
  
.trainMoving {
    height: 120%!important;
    width: 120%!important;
  }
  
.trainPosition{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   }

.trainBox{
     height: 200px;
     width: 200px;
}


/*   image css */

   
.large_size_img {
       height: 11em;
       width: 14em;
}

.medium_size_img{
    height: 8em;
    width: 10em;
}

.medium_size_img2{
    height: 6em;
    width: 12em;
}

.small_size_img{
    height: 6em;
    width:  8em;
}


.small_size_img2{
    height: 6em;
    width:  6em;
}
      
.very_small_img{
    width: 60px;
    height: 50px;
}

.teeny_tiny_img{
       height: 3em;
       width: 4 em;
}

.mood_retirement_icon_narrow_size{
    height: 25px;
    width: 100px;
}

.mood_retirement_icon_medium_size{
    height: 35px;
    width: 120px;
}

.mood_retirement_icon_wide_size{
    height: 65px;
    width: 240px;
}

.mood_retirement_icon_container {  
    background-image: url(../speedometerMoodRetirement.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mood_arrow_position_wide{
      height: 30px;
      width: 5px;   
      position: relative;
      top: 12px;
      left: 55px;
      transform-origin: 0% 100%;
      transform: translate(-55px, -15px);
}

.retirement_arrow_position_wide{
        height: 30px;
        width: 5px;   
        position: relative;
        top: 12px;
        left: 180px;
        transform-origin: 0% 100%;
        transform: translate(-180px, -15px);
}


.mood_arrow_position_medium{
    height: 18px;
    width: 3px;   
    position: absolute;
    top: 14px;
    left: 42px;
    transform-origin: 0% 100%;
}

.retirement_arrow_position_medium{
    height: 18px;
    width: 3px;   
    position: absolute;
    top: 14px;
    left: 105px;
    transform-origin: 0% 100%;
}

.mood_arrow_position_narrow{
    height: 15px;
    width: 3px;   
    position: absolute;
    top: 12px;
    left: 38px;
    transform-origin: 0% 100%;
}

.retirement_arrow_position_narrow{
    height: 15px;
    width: 3px;   
    position: absolute;
    top: 12px;
    left: 88px;
    transform-origin: 0% 100%;
}

.train_icon_thumbnail_img
{
    height: 2em;
    width: 7em;
 }

.train-track-img
{   
    position: absolute;
    top: 10%;
    height: 25em;
    width: 80%} 
 
 .card-img-top
{
    border-top-left-radius: 75px!important;
    border-top-right-radius: 75px!important;
    border: solid!important;
    border-color: rgba(255,255,255,0.3);
    border-width: 5px!important;
    height:14em;
}

.buttonDisabledImage {
    opacity: 0.5;
}

.text-pointer {
    cursor: pointer;
}

.timelineRectangle {
    display: inline-block;
    width: 20px;
    height: 86%;
    background-color: white;
    position: absolute;
    top:30px;
    left:45px
  }  

.btn-small-special {
    padding: 0.15rem 0.5rem;
    font-size: 0.83rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
.comment-icon-position
{
    position: absolute;
    top: 22%;
    right: 20%;
    z-index:  5;
}
 
 .comment-img
{
    height: 8%;
    width: 4%;
}   
  
.thumbsUp-position
{
    position: absolute;
    top: 10%;
    left: 70%;
    transform: translate(-70%, -10%);
    z-index: 5;
}


.thumbsSideways-position
{
    position: absolute;
    top: 10%;
    left: 80%;
    transform: translate(-80%, -10%);
    z-index: 5;
}
 
.thumbsDown-position
{
    position: absolute;
    top: 10%;
    left: 90%;
    transform: translate(-90%, -10%);
    z-index: 5;
}

.thumbs-img
{
    height: 15%;
    width: 8%;
}   

.no-gutter {
    margin-right: 0;
    margin-left: 0;
  }
  
  .no-gutter > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }

.card-no-margins{
    margin-right: 0;
    margin-left: 0;
}

.opacity-10-percent{
    opacity: .10;
}
  
.badge-position-1{
    position: absolute;
    left: 75%;
 }

 .badge-ribbon-position{
    position: absolute;
    top: 0%;
 }
 
 .badge-ribbon-position-medium{
    position: absolute;
    top: 6%;
 }
 
 .badge-ribbon-position-narrow{
    position: absolute;
    top: 2%;
 }

 .center-train-icon-in-footer{
    position: absolute;
    left: 35%;
    
 }
 
@keyframes rotateTrain{
    100% {transform: rotate(359deg)};
  }
  
  .money-train-spinner{
     animation: rotateTrain 2s infinite linear;
     animation-delay: 1s;
  }
  
  .wait-spinner-container{
      width: 300px;
      height: 300px;
  }

  .spinner-position{
     position: fixed;
     top: 40%;
     left: 12%;
     width: 70%;
     height: 70%;
  }

  .spinner-text-position{
     position: fixed;
     top: 50%;
     left: 48%;
     transform: translate(-50%, -50%);
     z-index: 5;
  }
  
  .spinner-wide-position{
    position: fixed;
    top: 40%;
    left: 43%;
    width: 70%;
    height: 70%;
 }

 .spinner-modal-position{
    position: fixed;
    top: 15%;
    left: 44%;
    width: 70%;
    height: 70%;
 }

 .spinner-text-wide-position{
    position: fixed;
    top: 50%;
    left: 45%;
    z-index: 5;
 }

 .btn-group-medium
{
    width: 70%;
    border-radius: 25px;
    border-width: 2px!important;
    border-color: #004274!important;
    background-color: #004274;
    padding: 5px;
    color: white;
}

  

  /* min screen size seems to be 640px 

    Small (smaller than 640px)
    Medium (641px to 1007px)
    Large (1008px and larger)   */