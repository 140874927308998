.button-placement
{
    position: absolute!important;
    right: 10%;
    top: 80%;
}

 .related-player-table-style
{
    width: 75%; 
    position: absolute;
    left: 20%;
}

.parent-board
{
    background-position: 0;
    background-size: 100%;
    min-height: 100%;
    width: 100%;
}

.table-header-background {
    background-color: #ADD1FF;
    height: 5%;
    width: 100%;
}

.video-modal-size {
     height: 150%!important;
     width: 100%!important;
}

.video-display-size {
    height: 80%!important;
    width: 50%!important;
    
}

